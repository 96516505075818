import * as React from 'react';

function SvgMh(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 80 64" {...props}>
      <path
        fill="currentColor"
        d="M34.45 12.194c7.153 0 10.855 4.594 10.855 11.33l-.002 21.717c-2.946.991-5.875 2.02-8.786 3.084l.004-23.102c0-3.336-1.631-5.54-4.768-5.54-3.012 0-4.706 2.204-4.706 5.54l-.005 26.717a361.59 361.59 0 00-8.781 3.627l.002-30.344c0-3.336-1.568-5.54-4.705-5.54-3.012 0-4.768 2.204-4.768 5.54l-.001 34.565A362.6 362.6 0 000 64l.006-50.485c0-.377.25-.63.627-.63h7.53c.376 0 .627.253.627.63v2.14h.062c1.38-1.888 3.953-3.461 7.843-3.461 3.702 0 6.4 1.321 8.156 3.84h.063c2.259-2.393 5.27-3.84 9.536-3.84zM61.115 0c.377 0 .628.252.628.63v15.025h.062c1.38-1.888 3.953-3.461 7.843-3.461C76.36 12.194 80 16.85 80 23.524l-.002 11.94c-2.943.672-5.871 1.38-8.786 2.124l.004-12.365c0-3.336-1.568-5.54-4.705-5.54-3.012 0-4.768 2.204-4.768 5.54l-.001 14.918c-2.945.837-5.874 1.71-8.788 2.62L52.96.628c0-.377.25-.629.627-.629h7.53z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgMh;
