import Footer from '@moonhatch/gatsby-theme-moonhatch/src/components/footer/footer';
import React from 'react';

import FooterComponent from '../../../../components/footer';

const FooterCustom = (props) => (
  <Footer {...props}>
    <FooterComponent />
  </Footer>
);

export default FooterCustom;
