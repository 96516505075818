import React from 'react';

import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { Mh } from '../../icons';
import style from './footer.module.scss';

export default () => {
  const { fullName, instagram } = useSiteMetadata();

  return (
    <div className={style.container}>
      <ul className={style.links}>
        <li>
          <a className={style.link} href={`https://instagram.com/${instagram}`}>
            Instagram
          </a>
        </li>
        <li>
          © {new Date().getFullYear()} {fullName}
        </li>
      </ul>
      <Mh className={style.icon} />
    </div>
  );
};
